import { Container } from '@material-ui/core'
import React from 'react'
import { Header1 } from '../../components/Headers/Headers'
import MapComponent from '../../components/MapComponent/MapComponent'
import SEO from '../../components/seo'

const GminyIndexPage = () => (
  <>
    <SEO title="Zabytki w poszczególnych gminach" />
    <Container style={{ marginTop: 30 }}>
      <Header1>Zabytki w poszczególnych gminach</Header1>
      <MapComponent />
    </Container>
  </>
)

export default GminyIndexPage
